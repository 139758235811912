import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Modal from '../components/Modal'
import EmailForm from '../components/EmailForm'
import IconView from '../components/IconView'
import DetailDemo from '../components/DetailDemo'
import serviceImg1 from '../assets/service1.png'
import serviceImg2 from '../assets/service2.png'
import serviceImg3 from '../assets/service3.png'

class Home extends Component {
	constructor(props) {
		super(props)

		this.state = {
			emailFormOpen: false
		}

		this.handleEmailFormClick = this.handleEmailFormClick.bind(this)
	}

	componentDidMount() {

	}

	handleEmailFormClick() {
		this.setState({ emailFormOpen: !this.state.emailFormOpen })
	}

	render() {
		const iconViewImg = [serviceImg1, serviceImg2, serviceImg3]
		const iconViewLable = ['Video Advertising Tools', 'Supply Side Platform', 'Demand Side Platform']
		const iconViewText = [
			`LaunchAd provides a range of advanced proprietary video ad units for different use cases. Our outstream (aka "ad-only") and in-stream video players enable content owners and publishers to monetize their content across desktop, mobile and connected TV devices programmatically.`,
			`With a growing network of premium publishers with both editorial and video inventory, LaunchAd reaches millions of new audiences globally across all screens, creating new opportunities for brands to unlock new potentials and expand their reach.`,
			`An aggregated pool of trusted advertisers get a direct pipeline to premium supply and innovative solutions for optimizing media efficiency, reach, and audience targeting. LaunchAd matches advertisers with their desired audiences through a network of verified premium publishers.`,
			`across demographics Get in front of a huge aggregated pool of trusted advertisers who bid against each other in real-time, resulting in the highest CPM for your inventory`
		]
		const iconViewBtn = ['Units', 'Publishers', 'Advertisers']
		const iconViewLinks = ['/publishers#solutions', '/publishers', 'advertisers']
		const detailDemoLabel = ['High Performance Video Players', 'Higher CPM and ROI', 'Cross-Screen Reach', 'Easy Integration']
		const detailDemoText = [
			'Whether you have video inventory or editorial content, our versatile high performance video players will give you all you need to monetize your online content',
			'The engine that runs our players, brings in the highest possible CPM for publishers, while making sure that advertisers get the most out of their ad spend',
			'Multi-platform reach across all screens, reaching a global audience',
			'Lightweight, fast, and easy to implement video players that support all the latest ad formats'
		]

		return (
			<div>
				{/*<SlideShow onEmailFormClick={this.handleEmailFormClick} />
				{this.state.emailFormOpen &&
					<Modal>
						<EmailForm onClose={this.handleEmailFormClick} />
					</Modal>
				}*/}
				{/*<div style={{display: "flex", justifyContent: "center"}}>
					<VideoPlayer { ...videoJsOptions } />
				</div>*/}
				<div className="home-landing">
					<div className="home-landing-title">
						<div>
							digital video advertising tools and solutions
						</div>
						<span>
							for premium publishers and advertisers
						</span>
						<HashLink to='/#services'>
							Learn More
						</HashLink>
					</div>
				</div>
				<div id="services" className="home-mid-section">
					<div className="home-mid-section-title">Video Advertising Marketplace</div>
					<div className="home-mid-section-subtitle">Video Advertising Solutions Driven by Results</div>
					<div className="home-services">
						{iconViewImg.map((img, i) =>
							<IconView
								img={img}
								label={iconViewLable[i]}
								text={iconViewText[i]}
								btn={iconViewBtn[i]}
								link={iconViewLinks[i]}
							/>
						)}
					</div>
				</div>
				<div className="home-mid-bottom">
					<div className="home-mid-bottom-title"><b>LaunchAd</b> Is Where Technology Meets Innovation</div>
					<div className="home-mid-bottom-content">
						{detailDemoLabel.map((label, i) => 
							<DetailDemo
								label={label}
								text={detailDemoText[i]}
							/>
						)}
					</div>
				</div>
				<div className="action-to-call">
					<h1>LaunchAd Today!</h1>
					<div className="action-items">
						<div>
							<h3>PUBLISHERS</h3>
							<p>Want to maximize your ad revenue by leveraging video ads, using easy-to-integrate video ad units that give you the highest CPM with incredible fill rates?</p>
						</div>
						<div>
							<h3>ADVERTISERS</h3>
							<p>Want to expand your video advertising reach to the huge audience that consume editorial content, while maintaining your in-stream presence?</p>
						</div>
					</div>
					<Link to='/contact' className="iconview-btn">Get in Touch</Link>
				</div>
			</div>
		)
	}
}

export default Home;
