import React from 'react'

const PrivacyPolicy = () => {
	return (
		<div className="pp-tc">
			<p>
				This privacy policy ("Privacy Policy") explains how LaunchAd ("LaunchAd", "Company", "we", "our" or "us") collects and uses information pursuant to offering LaunchAd products and services ("Services"). This Policy also explains how information is collected and used in connection with our website located at https://www.LaunchAd.com ("Website") as well as how information is collected directly from those who provide video and other types of content ("Publishers") and those who advertise their products and services ("Advertisers").

				By using or accessing the Services or the Website, You acknowledge and accept the practices described in this Policy. We reserve the right to change this Policy from time to time. Your continued use of the Website or Platform constitutes your acceptance of the Policy then in effect. For this reason, you should review the Policy from time to time to ensure that you understand and are willing to accept our current privacy practices.
			</p>
			<h3>Privacy Practices for our Services</h3>
			<p>
				Our Services may collect information from User interactions with the videos Publishers stream via our Services and tools, which may include (a combination of) Personal Data elements, such as:

				IP address,
				browser type,
				Internet Service Provider,
				computer (or mobile device's) operating system and mobile device identifiers.
				We may also collect the URL of the page where our Services are enabled, the referring URL, the version of the video player the Publisher is utilizing, when and how long the video was played, which advertising message(s) were inserted into the video, whether the video was played in fullscreen mode, and certain metadata about the video or the Publisher's webpage.

				These Personal data elements will be collected and used in the pseudonymized manner, which means they can not be separately attributed to identify and/or reveal a natural person's individual identity.

				We may use the collected Personal Data in a number of ways, such as:

				to help us ensure that the web content is deploying accurately,
				to help us, our Publishers and our Advertisers understand how the web content and advertising we deploy is being viewed by Users, and
				to help ensure that the advertising and videos are both appropriate and relevant to User interests.
				We may use cookies in association with our Products and Services to help us understand how our Products and Services are being used, tailor advertising, ad delivery and reporting purposes.

				Like many video technologies, we may use Flash and similar technologies to enable our video players. However, we don't use those technologies (e.g., Flash cookies) for advertising.
			</p>
			<h3>Privacy Practices for our Website</h3>
			<p>
				Our Website is directed primarily to Publishers and Advertisers. We may collect such Personal Data elements, as full name and email via the Website only when you choose to provide it to us.

				You can choose not to provide certain information, but this will limit your ability to purchase products offered on the Website, or to take advantage of some of our features for Website customers.

				To withdraw your consent to the collection and use of Personal Data by LaunchAd, please send us a an email at info@LaunchAd.com

				We may also opt-out of future marketing emails. To do this, please email us at info@LaunchAd.com
			</p>
			<h3>Use of Cookies and Similar Technology</h3>
			<p>
				We use cookie technology on our Website. The cookies we use do not reveal any personally identifiable information.

				We use cookies on this Website for a number of distinct purposes, such as:

				to help us determine which country you are in while visiting our Website,
				to enhance your experience and to determine for which one of our partners, if any, referred you to our Website.
				We also use third party analytics cookies and other tools such as Google Analytics on our Website to collect non-privacy intrusive Data.

				These third-party tools collect information such as how often users visit the Website, what pages they visit when they do so, and what other sites they used prior to coming to the Website.

				We use the information we get from our analytics partners to improve the Website. We do not combine the information collected through the use of analytics tools with Personal Data elements.

				We do not currently take action in response to browser based Do Not Track signals.

				For more information about cookies, web beacons, and your available opt-out choices, please visit www.allaboutcookies.org.
			</p>
			<h3>Disclosure of Information to Third Parties, Onward Transfer</h3>
			<p>
				LaunchAd may share your Personal Data elements with trusted third-party partners. These third-party contractors are prohibited from using the information for purposes other than performing services for LaunchAd.

				We may also share Personal Data with Publishers on how Users view videos using our Services.

				Such Personal Data will be disclosed in the pseudonymized manner, which means it cannot be separately attributed to identify/reveal a natural person's individual identity.

				LaunchAd may be required to disclose an individual's Personal Data in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.

				LaunchAd may also disclose your Personal Data to third parties when obligated to do so by law and in order to investigate, prevent, or take action regarding suspected, or actual prohibited activities, including but not limited to fraud and situations involving potential threats to the physical safety of any person.

				Finally, LaunchAd may transfer your Personal Data to a successor entity in connection with a corporate merger, consolidation, sale of assets, bankruptcy, or other corporate change.

				If LaunchAd is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via a notice on our Website of any change in ownership or uses of your personally identifiable information, as well as any choices you may have regarding your personally identifiable information.
			</p>
			<h3>Data Integrity, Purpose of Limitation</h3>
			<p>
				We process Personal Data in a way that is compatible with and relevant for the purpose for which it was collected as described above.

				To the extent necessary for those purposes, we take reasonable steps to ensure that any information in our care is accurate, complete, current and reliable for its intended use.
			</p>
			<h3>Data Retention</h3>
			<p>
				We store your Personal Data (e.g. email address, billing details) so long as you continue to have a business relationship with us.

				You may ask us to delete this Data by sending us an email at info@LaunchAd.com

				We store non-personally intrusive data, obtained from the Use of our Services, up to one year to ensure that our Digital Properties function properly.
			</p>
			<h3>Information Relating to Children</h3>
			<p>
				LaunchAd's Website and Services are designed for those 16 years of age and older. LaunchAd does not knowingly collect Personal Data from anyone under the age of 16.

				If the company is made aware that it has received Personal Data from someone under 16, it will use reasonable efforts to remove that information from its records.

				Additional Disclosures
				Legal Basis for Processing Personal Data of EEA and Swiss Individuals
				LaunchAd is committed to compliance to Personal Data privacy and protection laws and regulations, including: the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).

				If there is any conflict between the LaunchAd Terms of Service or Privacy Policy, the Policies covered in the Section shall govern.
			</p>
			<h3>Data Retention</h3>
			<p>
			Retention of personal information of EEA and Swiss individuals will be handled in accordance with LaunchAd Data Retention policy (as described above).
			</p>
			<h3>Accessing and Deleting Personal Data</h3>
			<p>
			EEA and Swiss individuals have the right to:

			request access to and rectification or erasure of Personal Data, obtained by LaunchAd;
			restrict the processing of their Personal Data, object to processing, and/or exercise the right of data portability; and
			withdraw consent to the collection and use of Personal Data by LaunchAd without affecting the lawfulness of processing and lodge a complaint with a supervisory authority.
			To exercise this right, please send us an email at info@LaunchAd.com

			LaunchAd is committed to resolving of complaints about our collection or use of your Personal Data.

			EEA or Swiss individuals with complaints or inquiries regarding this Privacy Policy should first contact LaunchAd by sending an email at info@LaunchAd.com
			</p>
			<h3>Disclosure of Personal Data to Third Parties, Onward Transfer</h3>
			<p>
			LaunchAd may transfer obtained Personal Data elements of EU and Swiss individuals to third parties obtained, as described above in this Privacy Policy. The disclosed Personal Data used for the purposes set forth in this Privacy Policy.

			To request notification of the intended purpose, disclosure, addition, correction, deletion, cessation of use, and cessation of disclosure to third parties of your Personal Data, please send us an email at info@LaunchAd.com
			</p>
			<h3>Notifying Third Parties about Personal Data Restriction, Rectification, Erasure</h3>
			<p>
			Where LaunchAd has disclosed Personal Data of its User to any third parties, and the User has subsequently exercised any of the rights of erasure, rectification,or blocking, LaunchAd will promptly notify these third parties of User's exercising of those rights.

			In no case LaunchAd shall be liable for the failure of any third party to comply with such User's request.

			LaunchAd shall be exempt from this obligation if it is impossible or would require disproportionate effort.
			</p>
			<h3>Collection and Use of Sensitive or Special Categories of Personal Data</h3>
			<p>
			LaunchAd does not knowingly collect, store or in any other way process Sensitive or Special Categories of User Data, such as:

			Personal Data, revealing ethnic or racial origin, religious/philosophical beliefs, political opinions, or trade-union membership;
			genetic data or biometric data;
			Personal Data concerning health or sex life and sexual orientation;
			Personal Data of Children below the age of 16 years.
			If LaunchAd is made aware that it has obtained such Sensitive Personal Data elements, we will use reasonable efforts to remove this Data from our records.
			</p>
			<h3>Questions to this Privacy Policy</h3>
			<p>
			If you have any questions or suggestions regarding this Policy, or a specific demand, please contact us at info@LaunchAd.com We will process your request within a reasonable period of time.
			</p>
		</div>
	)
}

export default PrivacyPolicy
