import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import playIcon from '../assets/play-icon.png'

const Navbar = () => {
	const menuItemsStyl = {

	}

	return (
		<div className="navbar">
		<div className="navbar-content">
				<Link to="/">
					<div className="navbar-logo">
						launch<div className="play-icon"><img src={playIcon} /></div>ad
					</div>
				</Link>
				<div className="navbar-menu-items">
					<NavLink to="/publishers" activeStyle={{color: 'red'}} activeClassName='active'>Publishers</NavLink>
					<NavLink to="/advertisers" activeStyle={{color: 'red'}} activeClassName='active'>Advertisers</NavLink>
					{/*<NavLink to="/about" activeStyle={{color: 'red'}} activeClassName='active'>About</NavLink>*/}
					<NavLink to="/contact" activeStyle={{color: 'red'}} activeClassName='active'>Contact</NavLink>
					<NavLink to="/login" activeStyle={{color: 'red'}} activeClassName='active'>Login</NavLink>
				</div>
			</div>
		</div>
	)
}

export default Navbar;
