import React from 'react'
import { Link } from 'react-router-dom'
import oldTv1 from '../assets/old-tv1.jpg'
import advertisers from '../assets/advertisers.png'

const Advertisers = () => {
	return (
		<div>
			<div className="advertisers-top">
				<div className="advertisers-top-placeholder">.</div>
				<div className="advertisers-top-content">
					<h1>Why Video?</h1>
					<p>
						Video has become such a natural part of the web browsing experience that advertisers can use it to quickly engage with their audience
						as if it was content they had sought out specifically.
						<br />
						<br />
						Video ads offer organizations an opportunity
						to connect on a deeper level right from the outset, by establishing a relationship, inviting the audience into the fold,
						and visually educating them in an effort to curate the buying journey.
					</p>
				</div>
			</div>
			<div className="advertisers-middle">
				<div className="advertisers-middle-left">
					<div className="advertisers-middle-title">Grow your audience and discover new opportunities</div>
					<div style={{fontSize: "18px", fontWeight: "400", color: "#919192", lineHeight: "1.4"}}>
						By tapping into our growing network of premium publishers who use our outstream video players,
						you can expand your reach while maintaining and increasing your presence in the in-stream marketplace!
						<br />
						<br />
						Now, you are able to host your video ad content on any type of outlet, whether or not the publisher frequently utilizes videos.
						This allows your organization to expand your advertising reach and tailor your videos more effectively for browsers of text-heavy publications.
					</div>
				</div>
				<div className="advertisers-middle-right">
					<ul>
						<li>Premium Editorial Content</li>
						<li>Verified Publishers</li>
						<li>Cross-Platform Delivery</li>
						<li>100% Viewability</li>
						<li>Transparent Supply Chain</li>
						<li>Higher Video View-Throughs</li>
						<li>Greater Engagement Rates</li>
						<li>Flexible CPM Rates</li>
					</ul>
				</div>
			</div>
			<div className="publishers-action">
				<div style={{fontSize: "45px", paddingBottom: "5px", borderBottom: "1px solid red"}}>LaunchAd Today!</div>
				<div className="publishers-action-image">
					<img src={advertisers} />
				</div>
				<h2 style={{fontWeight: "300"}}>Discover new grounds and be seen by millions!</h2>
				<Link to='/contact' className="iconview-btn">Contact Us</Link>
			</div>
		</div>
	)
}

export default Advertisers
