import React, { Component } from 'react'

class FloatingDemo extends Component {
	componentDidMount() {
		(function()
			{
			  var lkqdSettings = {
			    pid: 252,
			    sid: 1117815,
			    playerContainerId: '',
			    playerId: '',
			    playerWidth: 500,
			    playerHeight: 282,
			    execution: 'outstream',
			    placement: 'slider',
			    playInitiation: 'auto',
			    volume: 0,
			    trackImp: '',
			    trackClick: '',
			    custom1: '',
			    custom2: '',
			    custom3: '',
			    pubMacros: '',
			    dfp: false,
			    gdpr: '',
			    gdprcs: '',
			    us_privacy: '',
			    lkqdId: new Date().getTime().toString() + Math.round(Math.random()*1000000000).toString(),
			    supplyContentVideo: {
			      url: '', clickurl: '', play: 'post'
			    }
			  };

			  var lkqdVPAID;
			  var creativeData = '';
			  var environmentVars = { slot: document.getElementById(lkqdSettings.playerContainerId), videoSlot: document.getElementById(lkqdSettings.playerId), videoSlotCanAutoPlay: true, lkqdSettings: lkqdSettings };

			  function onVPAIDLoad()
			  {
			    lkqdVPAID.subscribe(function() { lkqdVPAID.startAd(); }, 'AdLoaded');
			  }

			  var vpaidFrame = document.createElement('iframe');
			  vpaidFrame.id = lkqdSettings.lkqdId;
			  vpaidFrame.name = lkqdSettings.lkqdId;
			  vpaidFrame.style.display = 'none';
			  var vpaidFrameLoaded = function() {
			    vpaidFrame.contentWindow.addEventListener('lkqdFormatsLoad', function() {
			      lkqdVPAID = vpaidFrame.contentWindow.getVPAIDAd();
			      onVPAIDLoad();
			      lkqdVPAID.handshakeVersion('2.0');
			      lkqdVPAID.initAd(lkqdSettings.playerWidth, lkqdSettings.playerHeight, 'normal', 600, creativeData, environmentVars);
			    });
			    var vpaidLoader = vpaidFrame.contentWindow.document.createElement('script');
			    vpaidLoader.setAttribute('async','async');
			    vpaidLoader.src = 'https://ad.lkqd.net/vpaid/formats.js';
			    vpaidFrame.contentWindow.document.body.appendChild(vpaidLoader);
			  };
			  vpaidFrame.onload = vpaidFrameLoaded;
			  vpaidFrame.onerror = vpaidFrameLoaded;
			  document.documentElement.appendChild(vpaidFrame);
			})();
	}

	render() {
		return (
			<div className="baseline-demo">
				<h2>Outstream Floating Video Ad Unit</h2>
				<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed magna ipsum, maximus ut velit eu, commodo imperdiet diam. Proin quis nulla rhoncus, tempus enim ac, dignissim ex. Donec sit amet fermentum orci. Donec a velit sem. Integer id posuere tellus. Nulla ac mi sit amet dolor viverra ullamcorper. Vestibulum gravida rutrum nibh, vel vestibulum justo viverra quis. Curabitur ut ligula ac elit aliquam molestie non nec eros. Morbi vulputate vitae mauris eu imperdiet. Etiam ultrices aliquam tempor. Morbi eu eros ornare, molestie nunc vitae, vulputate velit. Aliquam aliquam hendrerit ante vel bibendum. In bibendum diam felis, in condimentum felis egestas sed. Nullam accumsan sed felis sit amet aliquam. Proin eget efficitur erat. Nam convallis elit urna, sed ullamcorper enim lacinia a.
				<br />
				<br />
				Cras at tempus felis. Vivamus at ultricies massa. Aenean vel mi ut lectus ultrices scelerisque. Nulla cursus malesuada posuere. Donec eros ipsum, pharetra non mauris egestas, feugiat egestas ligula. Nulla rhoncus enim ac mauris lacinia pretium. Suspendisse tristique eget magna laoreet consequat. Nulla eget euismod massa. Nulla facilisi. Maecenas egestas dapibus malesuada. Suspendisse laoreet ullamcorper ex porta varius. Aliquam erat volutpat. Aliquam erat volutpat. Vivamus nec dolor turpis. Pellentesque metus diam, placerat ac faucibus in, porttitor non ante. Etiam hendrerit magna eu felis pellentesque mollis.
				<br />
				<br />
				Quisque efficitur, urna sit amet fermentum auctor, quam est lacinia augue, ac congue ex lorem ac enim. Aenean sit amet ullamcorper dui, at faucibus ipsum. Donec tempus diam ut sagittis consequat. Donec eu fermentum neque. Pellentesque commodo ut libero eu posuere. Duis vitae enim quis ex gravida faucibus. Suspendisse nisi neque, sodales nec augue quis, tempor laoreet velit. Donec mi eros, sollicitudin vitae metus sit amet, bibendum pulvinar augue. Nulla venenatis eleifend scelerisque. Donec hendrerit dapibus dolor et vulputate. In sed nunc id nulla varius scelerisque. Donec tincidunt lectus eget diam ullamcorper, sit amet molestie arcu semper.
				<br />
				<br />
				Maecenas imperdiet, nisi ut feugiat elementum, nulla mauris viverra velit, tincidunt ullamcorper mi magna sed sem. Praesent ornare sapien sit amet libero sagittis viverra sit amet finibus nisi. Duis eget luctus arcu, consectetur convallis ante. Suspendisse finibus lacus in nisl iaculis euismod eget sed justo. Etiam euismod, lacus sed blandit semper, est risus rutrum ipsum, eget vulputate tellus purus a tortor. Curabitur sollicitudin neque et augue commodo, a fermentum lacus venenatis. Aliquam venenatis facilisis justo ac venenatis. Vivamus lobortis nisl id orci consequat, eget elementum leo tincidunt. Proin sit amet metus mi.
				<br />
				<br />
				Pellentesque a interdum mauris. Curabitur eget lacus vitae eros pellentesque tincidunt sit amet vitae nulla. Donec quis mollis augue, facilisis venenatis metus. Nam molestie velit ligula, in porttitor nisl euismod non. Ut varius venenatis augue, sit amet dignissim ligula mattis nec. Duis dignissim est non dictum elementum. Aenean vel sem bibendum, consectetur massa quis, dignissim massa. Praesent rutrum porttitor diam, ac ultrices lectus efficitur consectetur.
				<br />
				<br />
				Cras at tempus felis. Vivamus at ultricies massa. Aenean vel mi ut lectus ultrices scelerisque. Nulla cursus malesuada posuere. Donec eros ipsum, pharetra non mauris egestas, feugiat egestas ligula. Nulla rhoncus enim ac mauris lacinia pretium. Suspendisse tristique eget magna laoreet consequat. Nulla eget euismod massa. Nulla facilisi. Maecenas egestas dapibus malesuada. Suspendisse laoreet ullamcorper ex porta varius. Aliquam erat volutpat. Aliquam erat volutpat. Vivamus nec dolor turpis. Pellentesque metus diam, placerat ac faucibus in, porttitor non ante. Etiam hendrerit magna eu felis pellentesque mollis.
				<br />
				<br />
				Quisque efficitur, urna sit amet fermentum auctor, quam est lacinia augue, ac congue ex lorem ac enim. Aenean sit amet ullamcorper dui, at faucibus ipsum. Donec tempus diam ut sagittis consequat. Donec eu fermentum neque. Pellentesque commodo ut libero eu posuere. Duis vitae enim quis ex gravida faucibus. Suspendisse nisi neque, sodales nec augue quis, tempor laoreet velit. Donec mi eros, sollicitudin vitae metus sit amet, bibendum pulvinar augue. Nulla venenatis eleifend scelerisque. Donec hendrerit dapibus dolor et vulputate. In sed nunc id nulla varius scelerisque. Donec tincidunt lectus eget diam ullamcorper, sit amet molestie arcu semper.
				<br />
				<br />
				Maecenas imperdiet, nisi ut feugiat elementum, nulla mauris viverra velit, tincidunt ullamcorper mi magna sed sem. Praesent ornare sapien sit amet libero sagittis viverra sit amet finibus nisi. Duis eget luctus arcu, consectetur convallis ante. Suspendisse finibus lacus in nisl iaculis euismod eget sed justo. Etiam euismod, lacus sed blandit semper, est risus rutrum ipsum, eget vulputate tellus purus a tortor. Curabitur sollicitudin neque et augue commodo, a fermentum lacus venenatis. Aliquam venenatis facilisis justo ac venenatis. Vivamus lobortis nisl id orci consequat, eget elementum leo tincidunt. Proin sit amet metus mi.
				<br />
				<br />
				Pellentesque a interdum mauris. Curabitur eget lacus vitae eros pellentesque tincidunt sit amet vitae nulla. Donec quis mollis augue, facilisis venenatis metus. Nam molestie velit ligula, in porttitor nisl euismod non. Ut varius venenatis augue, sit amet dignissim ligula mattis nec. Duis dignissim est non dictum elementum. Aenean vel sem bibendum, consectetur massa quis, dignissim massa. Praesent rutrum porttitor diam, ac ultrices lectus efficitur consectetur.
				<br />
				<br />
				Quisque efficitur, urna sit amet fermentum auctor, quam est lacinia augue, ac congue ex lorem ac enim. Aenean sit amet ullamcorper dui, at faucibus ipsum. Donec tempus diam ut sagittis consequat. Donec eu fermentum neque. Pellentesque commodo ut libero eu posuere. Duis vitae enim quis ex gravida faucibus. Suspendisse nisi neque, sodales nec augue quis, tempor laoreet velit. Donec mi eros, sollicitudin vitae metus sit amet, bibendum pulvinar augue. Nulla venenatis eleifend scelerisque. Donec hendrerit dapibus dolor et vulputate. In sed nunc id nulla varius scelerisque. Donec tincidunt lectus eget diam ullamcorper, sit amet molestie arcu semper.
				<br />
				<br />
				Maecenas imperdiet, nisi ut feugiat elementum, nulla mauris viverra velit, tincidunt ullamcorper mi magna sed sem. Praesent ornare sapien sit amet libero sagittis viverra sit amet finibus nisi. Duis eget luctus arcu, consectetur convallis ante. Suspendisse finibus lacus in nisl iaculis euismod eget sed justo. Etiam euismod, lacus sed blandit semper, est risus rutrum ipsum, eget vulputate tellus purus a tortor. Curabitur sollicitudin neque et augue commodo, a fermentum lacus venenatis. Aliquam venenatis facilisis justo ac venenatis. Vivamus lobortis nisl id orci consequat, eget elementum leo tincidunt. Proin sit amet metus mi.
				<br />
				<br />
				Pellentesque a interdum mauris. Curabitur eget lacus vitae eros pellentesque tincidunt sit amet vitae nulla. Donec quis mollis augue, facilisis venenatis metus. Nam molestie velit ligula, in porttitor nisl euismod non. Ut varius venenatis augue, sit amet dignissim ligula mattis nec. Duis dignissim est non dictum elementum. Aenean vel sem bibendum, consectetur massa quis, dignissim massa. Praesent rutrum porttitor diam, ac ultrices lectus efficitur consectetur.
				</p>
			</div>
		)
	}
}

export default FloatingDemo
