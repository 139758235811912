import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ReactGA from 'react-ga'
import logo from './logo.svg'
import './App.css';
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import PrivacyPolicy from './components/PrivacyPolicy'
import TandC from './components/TandC'
import Home from './pages/Home'
import Publishers from './pages/Publishers'
import Advertisers from './pages/Advertisers'
import Contact from './pages/Contact'
import Login from './pages/Login'
import BaselineDemo from './pages/BaselineDemo'
import BannerDemo from './pages/BannerDemo'
import FloatingDemo from './pages/FloatingDemo'

class App extends Component {
  render() {
    ReactGA.initialize('UA-132185732-1')

    return (
      <Router>
        <ScrollToTop>
          <div className="app-container">
            <Route path="/" component={logPageView} />
            <Navbar />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/publishers' component={Publishers} />
              <Route path='/advertisers' component={Advertisers} />
              <Route path='/contact' component={Contact} />
              <Route path='/login' component={Login} />
              <Route path='/privacyPolicy' component={PrivacyPolicy} />
              <Route path='/terms' component={TandC} />
              <Route path='/inBannerDemo' component={BannerDemo} />
              <Route path='/baselineDemo' component={BaselineDemo} />
              <Route path='/floatingDemo' component={FloatingDemo} />
              <Route render={() => <h1 style={{padding: "200px"}}>Page Not Found</h1>} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

const logPageView = (props) => {
  ReactGA.set({ page: props.location.pathname + props.location.search })
  ReactGA.pageview(props.location.pathname + props.location.search)

  return null
}

export default App;
