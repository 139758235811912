import React from 'react'

const DetailDemo = ({ label, text }) => {
	return (
		<div className="detail-demo">
			<div className="detail-demo-label">{label}</div>
			<div className="detail-demo-text">{text}</div>
		</div>
	)
}

export default DetailDemo
