import React from 'react'
import { Link } from 'react-router-dom'
import igLogo from '../assets/ig-logo.png'
import inLogo from '../assets/in-logo.png'
import playIcon from '../assets/play-icon.png'
import vast from '../assets/vast.png'
import vpaid from '../assets/vpaid.png'
import mraid from '../assets/mraid.png'
import openrtb from '../assets/openrtb.png'

const Footer = () => {
	return (
		<div className="footer">
			<Link to="/">
				<div className="navbar-logo" style={{justifyContent: "center"}}>
					launch<div className="play-icon"><img src={playIcon} /></div>ad
				</div>
			</Link>
			<div style={{marginTop: "15px"}}>
				<img src={vast} />
				&nbsp;
				<img src={vpaid} />
				&nbsp;
				<img src={mraid} />
				&nbsp;
				<img src={openrtb} />
			</div>
			<h3 style={{color: "#eaeaea"}}>Find us on</h3>
			<div className="social-media">
				<div onClick={() => window.open('https://www.linkedin.com/company/launch-ad/')}><img src={inLogo} /></div>
				&nbsp;
				<div onClick={() => window.open('https://www.instagram.com/launchad/')}><img src={igLogo} /></div>
			</div>
			<div className="policies">
				<Link to='/privacyPolicy'>Privacy Policy</Link>
				<span style={{color : "#eaeaea"}}> | </span>
				<Link to='/terms'>Terms & Conditions</Link>
				<br />
				<span style={{color : "#eaeaea", fontSize: "14px"}}>&copy;LaunchAd, LLC. 2020 All Rights Reserved</span>
				<br />
				<span style={{color : "#eaeaea", fontSize: "14px"}}>4052 Del Rey Ave #102, Marina Del Rey, CA 90292</span>
			</div>
		</div>
	)
}

export default Footer
