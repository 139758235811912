import React from 'react'
import { Link } from 'react-router-dom'
import outstream1 from '../assets/outstream1.png'
import outstream2 from '../assets/outstream2.png'
import outstream3 from '../assets/outstream3.png'
import outstream4 from '../assets/outstream4.png'
import instreamPlayer from '../assets/player-sketch.png'
import publishers from '../assets/publishers.png'

const Publishers = () => {
	const outstreamImgs = [outstream1, outstream2, outstream3, outstream4]

	return (
		<div>
			<div className="publishers-bg"></div>
			<div className="publishers-content">
				<div className="publishers-content-container">
					<div className="publishers-outstream">
						<div className="pubs-outstream-content">
							<div style={{fontSize: "23px", marginTop: "20px", marginBottom: "20px", borderBottom: "1px solid red"}}>OUTSTREAM</div>
							<div style={{fontSize: "20px", fontWeight: "700"}}>No video content? No worries!</div>
							<p>
								<b>LaunchAd</b> offers a variety of "ad-only" video ad units which can be placed anywhere along with your editorial content.
								These players do not require a video content, and automatically start playing muted ads once the user visits the page. They are VAST/VPAID
								compliant and work on both mobile and desktop!
								<br />
								<br />
								You can start generating higher video ad revenue instantly without even owning any video inventory!
							</p>
						</div>
						<div className="pubs-outstream-image">
							{outstreamImgs.map(img => <div><img src={img}/></div>)}
						</div>
					</div>
				</div>
				<div className="publishers-instream">
					<div className="pubs-instream-content">
						<div className="pubs-instream-image">
							<img src={instreamPlayer} />
						</div>
					</div>
					<div className="pubs-instream-content">
						<div style={{fontSize: "23px", marginTop: "20px", marginBottom: "20px", borderBottom: "1px solid red"}}>IN-STREAM</div>
						<p>
							Our in-stream player will monetize your video inventory with pre-roll, mid-roll, and post-roll ads generating the highest CPMs and fill rates,
							while making sure your content plays smoothly with the best quality!
						</p>
					</div>
				</div>
				<div className="publishers-action">
					<div style={{fontSize: "45px", paddingBottom: "5px", borderBottom: "1px solid red"}}>LaunchAd Today!</div>
					<div className="publishers-action-image">
						<img src={publishers} />
					</div>
					<h2 style={{fontWeight: "300"}}>Start monetizing your content and maximize your ad revenue!</h2>
					<Link to='/contact' className="iconview-btn">Contact Us</Link>
				</div>
			</div>
		</div>
	)
}

export default Publishers
