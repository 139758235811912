import React from 'react'
import EmailForm from '../components/EmailForm'

const Contact = () => {
	return (
		<div className="contact-page">
			<EmailForm />
		</div>
	)
}

export default Contact
