import React, { Component } from 'react'

class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			password: '',
			authorized: true
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
	}

	handleInputChange(event) {
		const name = event.target.name
		const value = event.target.value

		this.setState({
			[name]: value
		})
	}

	handleFormSubmit(event) {
		event.preventDefault()

		this.setState({ authorized: false })
	}

	render() {
		return (
			<div className="login-page">
				<form className="login-form" onSubmit={this.handleFormSubmit}>
					<label>
						Email
						<br />
						<input name="email" type="email" value={this.state.email} onChange={this.handleInputChange} required />
					</label>
					<label>
						Password
						<br />
						<input name="password" type="password" value={this.state.password} onChange={this.handleInputChange} required />
					</label>
					<button>
						Sign In
					</button>
				</form>
				<div className="login-response-text">
					{!this.state.authorized &&
						<span style={{color: "red"}}>Invalid email/password!</span>
					}
				</div>
			</div>
		)
	}
}

export default Login
