import React, { Component } from 'react'
import axios from 'axios'

class EmailForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			firstName: "",
			lastName: "",
			company: "",
			email: "",
			message: "",
			website: "",
			sending: false
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleFormSubmit = this.handleFormSubmit.bind(this)
	}

	handleInputChange(event) {
		const name = event.target.name
		const value = event.target.value

		this.setState({
			[name]: value
		})
	}

	async handleFormSubmit(event) {
		event.preventDefault()

		this.setState({ sending: true })

		axios.post('https://8euaahtvud.execute-api.us-east-1.amazonaws.com/prod/submitRequest', this.state)
			.then(res => {
				if(res.status === 200) {
					alert("Thank you for your interest. Someone will contact you shortly!")
					this.setState({ sending: false })
				}
			})
			.catch(err => {
				alert('There was an error processing your request, please try again!')
				this.setState({ sending: false })
			})
	}

	render() {
		return (
			<form onSubmit={this.handleFormSubmit} className="email-form">
				<label>
					First Name*
					<br />
					<input name="firstName" type="text" value={this.state.firstName} onChange={this.handleInputChange} required />
				</label>
				<label>
					Last Name*
					<br />
					<input name="lastName" type="text" value={this.state.lastName} onChange={this.handleInputChange} required />
				</label>
				<label>
					Email*
					<br />
					<input name="email" type="email" value={this.state.email} onChange={this.handleInputChange} required />
				</label>
				<label>
					Company
					<br />
					<input name="company" type="text" value={this.state.company} onChange={this.handleInputChange} />
				</label>
				<label>
					Website
					<br />
					<input name="website" type="text" value={this.state.website} onChange={this.handleInputChange} />
				</label>
				<label>
					Message*
					<br />
					<textarea name="message" value={this.state.message} onChange={this.handleInputChange} required />
				</label>
				<button>
					{this.state.sending ? 'Sending..' : 'Submit'}
				</button>
			</form>
		)
	}
}

export default EmailForm;
