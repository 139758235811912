import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const IconView = ({ img, label, text, btn, link }) => {
	return (
		<div className="iconview">
			<div className="iconview-icon" style={{backgroundImage: `url(${img})`}}></div>
			<div className="iconview-label">{label}</div>
			<div className="iconview-text">{text}</div>
			<NavLink to={link} className="iconview-btn">LaunchAd&trade; {btn}</NavLink>
		</div>
	)
}

export default IconView;
