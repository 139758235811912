import React from 'react'

const TandC = () => {
	return (
		<div className="pp-tc">
			<p>
			Please read these Terms of Service carefully before any Use of the Products. Your Use constitutes an unconditional agreement to be bound by these Terms of Service. IF YOU DO NOT ACCEPT THE TERMS OF SERVICE YOU ARE PROHIBITED FROM USING THE PRODUCTS.

			The Products are available in different Editions. Certain rights, obligations and restrictions vary by Edition. Unless otherwise stated, terms apply to all Editions.

			</p>
			<h3>Definitions</h3>
			<p>
			<ol>
			<li>Adaptation means any work that modifies, adds to, deletes from, edits, affects the function of or is based on a Product or any previous Adaptation thereof, including all derivative works of a Product as such term is defined by the Copyright Act.
			</li>
			<li>Advertising means a paid form of promotion using any type of Content, distributed through Products.
			</li>
			<li>Claims means claims, suits, demands and actions by third parties.
			</li>
			<li>Company (We) means LaunchAd.
			</li>
			<li>Content means media, including but not limited to videos, images, graphics, animations, sounds, text and music and any related submissions, displayed, streamed, or played through the Product.
			</li>
			<li>Documentation means any technical or other specifications or documentation that Company may provide to Publisher for use in connection with Products.
			</li>
			<li>Data means all transactional and Content data obtained by, processed by or provided to the Company through the Use of Products. Data may include metadata (such as title, author, description and other information pertaining to Content), IP addresses, mobile device identifiers, URLs, browser and operating system. Company's use of Data is limited to that which is permitted by applicable law, the Privacy Policy and these Terms of Service.
			</li>
			<li>Distribute means to sell, transfer, assign, circulate, disseminate, distribute or make available to any third party, or the facilitation of the foregoing.
			</li>
			<li>Edition means a particular edition of LaunchAd.
			</li>
			<li>Effective Date means the earliest date a Product is Used by Publisher unless otherwise provided on an applicable Order Form.
			</li>
			<li>End User means an individual or entity that accesses or Uses Publisher's Content or Adaptations.
			</li>
			<li>Fee means the amount payable to Company for Use of Products.
			</li>
			<li>Licensing Key (or License Key) means a certain piece of code in the Products that allows Company to verify the authorized Use of Products.
			</li>
			<li>Losses means damages, losses, costs and liabilities (including reasonable attorney and professional fees) arising from a Claim.
			</li>
			<li>Order Agreement is an agreement between Company and Publisher setting forth custom pricing and terms for the Use of Products. In the event of any conflict between the terms of an Order Agreement and these Terms of Service, the Order Agreement shall take precedence.
			</li>
			<li>Outstream Player is a version of the LaunchAd player that only plays Advertising and/or Streaming Videos and is loaded from one or more ad servers.
			</li>
			<li>Products means the Licensed Programs and Services (as applicable to the relevant Edition).
			</li>
			<li>Prohibited Content means any Content that (i) infringes any copyright, trademark, patent, trade secret, right of publicity or other right of any person or entity, (ii) is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive, tortious, obscene, offensive, vulgar, pornographic, profane or is otherwise inappropriate as determined by the Company in its sole discretion, (iii) contains any virus, malicious code, Trojan horse or other program designed, intended or likely to disrupt, damage, limit or interfere with the proper function of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password or other information of the Company or any person or entity, (iv) encourages illegal activity or (v) falsely suggests or implies sponsorship or endorsement by the Company.
			</li>
			<li>Publisher or You means you and any entity on whose behalf you Use Products.
			</li>
			<li>Publisher Information means personal and non-personal information about Publisher including but not limited to Publisher's name, email address, likeness.
			</li>
			<li>Parties means Company and Publisher.
			</li>
			<li>Services means services provided to Publisher under these Terms of Service, including cloud-hosted versions of LaunchAd provided as software as a service (SaaS), maintenance and support services.
			</li>
			<li>Start (or Player Start) is a single play of Content in whole or in part. In regards to the Use of the Outstream Player, a Start refers to a moment when ads start loading (one or more requests, to one or more ad servers, initiated at the same time; including all the waterfall requests).
			</li>
			<li>Terms of Service or TOS means this document, including any applicable, mutually ratified Order Form and/or Addendum, which shall be incorporated into these Terms of Service.
			</li>
			<li>Unpaid means any Products or Services for which Fees are not required.
			</li>
			<li>Use means operating, accessing, downloading, installing, integrating or providing Content through a Product or Adaptation.
			</li>
			</ol>
			</p>
			<h3>Products</h3>
			<p>
			These Terms of Service apply to your access and use of LaunchAd, provided by LaunchAd LLC ("LaunchAd", "we", "our" or "us"). Any Use of the Products other than as permitted by these Terms of Service is strictly prohibited. The Company reserves all rights and licenses not expressly granted hereunder.

			</p>
			<h3>Licenses</h3>
			<p>
			The License Scope varies by Edition and the licenses granted hereunder are limited accordingly. Unless otherwise provided, licenses are personal to Publisher for its own Use.

			Subject to the terms and conditions of these Terms of Service and Publisher's observance thereof, Company hereby grants to Publisher and Publisher hereby accepts a non-exclusive, non-transferable, non-sublicensable, limited right and license, solely for the Purpose, to Use, access and install the Products.

			</p>
			<h3>Adaptations</h3>
			<p>
			Publisher is authorized to make Adaptations reasonably necessary to exercise its rights under these Terms of Service. All Adaptations created by Publisher are strictly for Use in connection with the Purpose. Publisher is solely responsible to ensure that its Adaptations (except the Products incorporated therein) comply with all applicable laws and regulations and do not infringe the intellectual property rights of any third party. Company reserves the right to prohibit the Use or Distribution of any Adaptation that it reasonably believes violates or is likely to violate such laws, regulations or rights and if Publisher fails to cure the foregoing within fifteen (15) days after written notice thereof.

			</p>
			<h3>Distribution</h3>
			<p>
			Except pursuant to the Purpose or as otherwise specifically set forth herein, Publisher shall not Distribute, re-license, sell, lease, transfer, encumber, assign or make available for public use the Products or any Adaptation thereof. Any attempt to take any such actions is void and constitutes a material breach of these Terms of Service.

			</p>
			<h3>Termination</h3>
			<p>
			These Terms of Service are subject to termination by Company immediately if (a) Publisher violates any term of these Terms of Service, (b) Publisher is or becomes engaged in a business that is competitive with the Company's business, or (c) Publisher exceeds the License Scope permitted for its Edition and Publisher fails to cure any of the foregoing within seven (7) days of notice thereof.

			An Order Agreement shall provide additional terms, where:

			Initial Term is the minimum period of time these Terms of Service shall remain effective.
			Upon the conclusion of the Initial Term, these Terms of Service shall renew automatically for additional successive terms (each a Renewal Term, and together with the Initial Term, the Term).
			Either party may choose not to renew these Terms of Service by notifying the other no later than thirty (30) days prior to the expiration of the current Initial Term or Renewal Term. The notice must be provided in writing. These Terms of Service are subject to termination by the Company immediately if (a) Publisher violates any term of these Terms of Service, (b) the Company has a reasonable basis to believe that Publisher is not abiding by the spirit of these Terms of Service, (c) the Company has a reasonable basis to believe that Publisher is impairing the Company's business, (d) Publisher is or becomes engaged in a business that is directly or indirectly competitive with the Company's business, or (e) Publisher exceeds the Edition Scope permitted for its Edition and fails to cure any of the foregoing within seven (7) days of notice thereof.
			</p>
			<h3>Effects of Termination</h3>
			<p>
			Upon termination of these Terms of Service for any reason, Publisher shall immediately (24 hours since termination notice) cease Using the Products and uninstall, delete and destroy any copies of the Licensed Programs under its control.

			Upon termination, Company may delete any information, files or materials in or related to Publisher's account, and Company will have no obligation whatsoever to save or make any such Content, information, files or materials available to Publisher or any other party.

			Upon termination, all rights, obligations and licenses of the parties shall cease, except that all obligations that accrued prior to the effective date of termination and remedies for breach of these Terms of Service shall survive.

			</p>
			<h3>Maintenance and Support</h3>
			<p>
			Company will provide technical support and maintenance for the Products in its sole discretion unless otherwise provided, and Company shall have no obligation to provide additional technical support, maintenance, updates or enhancements to the Products or to provide customer service or support to Publisher's End Users.

			All installation and maintenance of Products by Publisher shall be at the sole expense of Publisher. Company is not responsible for performance issues caused by low-performance End-User computers, low-bandwidth End-User Internet connections, periodic network congestion among network providers, low-bandwidth connectivity from redirected image and video sources, improperly configured campaigns and low-bandwidth connectivity from any Publisher Content servers. In the event Company identifies slow performance due to its system, Company shall use commercially reasonable efforts to restore the Services to acceptable performance levels. Publisher's sole remedy for any disruption or failure to provide support shall be a pro rata refund of the Fee paid by Publisher for the relevant period.

			</p>
			<h3>Intellectual Property</h3>
			<p>
			The Company shall retain all intellectual property rights pertaining to the Products, including but not limited to copyright, trademark and patent rights and all rights not expressly granted herein are reserved. If Publisher creates an Adaptation, Publisher shall obtain all intellectual property rights in and to the elements of such Adaptation created by Publisher and the Company shall retain all intellectual property rights in and to any elements of the Products incorporated in such Adaptation. Publisher is prohibited from creating any Adaptation that in any manner infringes the patents, trademarks, copyrights, trade secret rights or other rights of the Company or any third party.

			</p>
			<h3>Privacy</h3>
			<p>
			Exercise of the rights and licenses granted hereunder shall be subject to the Company's Privacy Policy, currently located at https://LaunchAd/privacy, which is incorporated by reference herein. Publisher shall place prominently and maintain on all locations on which it Uses the Products, and comply with, a privacy statement or policy that adheres to or exceeds applicable law.

			Service Analytics may collect aggregated, non-personally identifiable information and Publisher grants Company a limited, royalty-free license to use such information to facilitate the operation of Products and for its internal business purposes.

			Company may use Publisher Information and Data to: deliver the Products; provide customer support; communicate with Publisher by email, develop and display content and advertising tailored to your interests; enforce our Terms of Service; manage our business; and perform functions otherwise described to you at the time of collection.

			</p>
			<h3>Restrictions on Use</h3>
			<p>
			Publisher shall not (i) interfere or attempt to interfere with the proper operation of the Products or any activities conducted through the Products, (ii) use any robot, spider or other device to retrieve, index, scrape, data mine or in any way gather information, Content or other materials from the Products, (iii) decipher, decompile, disassemble, reverse engineer, simulate or derive any source code or algorithms from the Products or use any of the foregoing to create any software or service similar to the Products, (iv) create any derivative work or modification of the Products other than Adaptations permitted by these Terms of Service or (v) license, sublicense, sell, encumber, rent, lease, Distribute, transfer or similarly exploit the Products.

			</p>
			<h3>Representations and Warranties</h3>
			<p>
			Publisher represents and warrants that (i) if Publisher is an individual, Publisher is at least eighteen (18) years old, (ii) if Publisher is an entity, its consent to these Terms of Service has been duly ratified, (iii) all its Content conforms to the standards set forth in the Sections of these Terms of Service titled Content and Restrictions on Use, (iv) all information provided during the registration process is true and accurate, (v) its Adaptations do not infringe the intellectual property rights of any third party and (vi) its Use of Products is in compliance with all applicable laws and regulations.

			</p>
			<h3>Limited Product Warranty</h3>
			<p>
			The Company warrants that it will provide the Products in a manner consistent with general industry standards reasonably applicable to the provision thereof. The Company does not warrant that the Products will be error-free or that any errors in the Products will be corrected.

			</p>
			<h3>Disclaimer</h3>
			<p>
			EXCEPT AS OTHERWISE PROVIDED AND TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE PRODUCTS AND RECOMMENDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OR CONDITIONS OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. EXCEPT AS OTHERWISE PROVIDED, USE OF THE PRODUCTS IS AT PUBLISHER'S OWN RISK AND PUBLISHER IS SOLELY RESPONSIBLE FOR DETERMINING THE APPROPRIATENESS OF EXERCISING THE RIGHTS GRANTED UNDER THIS AGREEMENT AND ASSUMES ALL RISKS ASSOCIATED THEREWITH, INCLUDING BUT NOT LIMITED TO THE RISKS AND COSTS OF PROGRAM ERRORS, COMPLIANCE WITH APPLICABLE LAWS, DAMAGE TO OR LOSS OF DATA, PROGRAMS OR EQUIPMENT, SUITABILITY FOR HOSTING AND UNAVAILABILITY OR INTERRUPTION.

			</p>
			<h3>Limitation of Liability</h3>
			<p>
			EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW OR TO THE EXTENT THAT ANY EXCLUSION OR LIMITATION OF ITS LIABILITY IS VOID, PROHIBITED OR UNENFORCEABLE, IN NO EVENT SHALL THE COMPANY BE LIABLE UNDER ANY EQUITY, COMMON LAW, CONTRACT, ESTOPPEL, NEGLIGENCE, TORT, STRICT LIABILITY OR OTHER THEORY (REGARDLESS OF THE FORM OF ACTION) (A) FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING LOSS OF PROFITS, REVENUES, SAVINGS, CUSTOMERS, OPPORTUNITIES OR GOODWILL), (B) FOR ANY MATTER BEYOND ITS REASONABLE CONTROL, (C) FOR ANY LOSS, CORRUPTION OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE OR COST OF PROCURING SUBSTITUTE TECHNOLOGY OR (D) DIRECT DAMAGES IN THE CUMULATIVE AGGREGATE IN EXCESS OF THE TOTAL FEES PAID BY PUBLISHER TO THE COMPANY PURSUANT TO THESE TERMS OF SERVICE, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN. EACH PARTY AGREES AND DOES WAIVE TRIAL BY JURY IN ANY ACTION, PROCEEDING OR COUNTERCLAIM BROUGHT AGAINST THE OTHER PARTY FOR ANY MATTER WHATSOEVER ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS OF SERVICE. NO ACTION, SUIT OR PROCEEDING SHALL BE BROUGHT AGAINST COMPANY MORE THAN ONE YEAR AFTER THE DATE OF RELATED SERVICES UNDER THESE TERMS OF SERVICES.

			</p>
			<h3>Indemnity</h3>
			<p>
			Except as otherwise set forth in these Terms of Service, Publisher will indemnify, defend and hold harmless the Company from and against all Claims, and for all resulting Losses (including attorney's fees) that result or arise from Claims, which in whole or in part, pertain to any Publisher Adaptation or arise from Publisher's Use, reproduction, Distribution or other exploitation of the Products. Publisher will pay all amounts agreed to in a monetary settlement of the Claims and all Losses that result or arise from the Claims. Notwithstanding the foregoing, Publisher shall have no duty to indemnify or defend the Company from Claims which primarily allege that Products, as provided to Publisher by the Company and used in accordance with the terms hereof, infringe the copyright, patent or trademark rights of a third party.

			</p>
			<h3>Confidential Information</h3>
			<p>
			Publisher acknowledges that it may have access to certain confidential information of the Company, such as information concerning its business, plans, customers, technology and products ("Confidential Information"). Confidential Information will include all information in tangible or intangible form that is marked or designated as confidential or that, under the circumstances of its disclosure, should reasonably be considered confidential. "Confidential Information" shall not include (a) information that becomes known to the general public without breach of the nondisclosure obligations of these Terms of Service; (b) information that is already known to Publisher at the time that it is disclosed to the receiving party; (c) information that is obtained from a third party without breach of a nondisclosure obligation and without restriction on disclosure; and (d) information that has been independently developed by Publisher without reference to any of the disclosing party's Confidential Information. Publisher agrees that it will not use Confidential Information in any way for its own account or the account of any third party, except as expressly permitted by, or required to achieve the purposes of, these Terms of Service, nor will it disclose to any third party (except as required by law or its attorneys, accountants and other advisors as reasonably necessary) any of the Confidential Information and it will take reasonable precautions to protect the confidentiality of such information, at least as stringent as it takes to protect its own Confidential Information. Upon termination or expiration of these Terms of Service for any reason, Publisher will return or destroy, at the Company's request, all Confidential Information and all documents or media containing any such Confidential Information and any and all copies or extracts thereof.

			</p>
			<h3>Publicity</h3>
			<p>
			Company shall be permitted to publicly identify Publisher as a user of the Products, including on Company's websites and marketing materials. Publisher hereby grants Company a non-exclusive, non-transferable, non-sublicensable, royalty-free license during the Term to use Publisher's name and marks solely for this purpose. Company agrees to state on all materials using Publisher's marks that such marks are property of Publisher and to include the trademark registration symbol if applicable. All goodwill arising out of any use of any of the Publisher's marks by Company will inure solely to the benefit of Publisher.

			</p>
			<h3>Severability</h3>
			<p>
			If for any reason a court of competent jurisdiction finds any provision of these Terms of Service, or portion thereof, to be unenforceable, that provision of the Terms of Service shall be enforced to the maximum extent permissible so as to effectuate the intent of the parties, and the remainder of these Terms of Service shall continue in full force and effect.

			</p>
			<h3>Choice of Law and Jurisdiction</h3>
			<p>
			These Terms of Service shall be governed by and construed in accordance with the laws of the State of New York (except that body of law controlling conflict of laws) and specifically excluding from application to this Agreement that law known as the United Nations Convention on Contracts for the International Sale of Goods.

			Each party irrevocably consents to the exclusive jurisdiction, forum and venue of the Commercial Division of the Supreme Court of the State of New York, New York County and the United States District Court for the Southern District of New York over any and all claims, disputes, controversies or disagreements between the parties or any of their respective subsidiaries, affiliates, successors and assigns under or related to these Terms of Service or the subject matter thereof.

			</p>
			<h3>Injunctive Relief</h3>
			<p>
			Publisher acknowledges and agrees that unauthorized Use of the Products or disclosure of Confidential Information may cause the Company irreparable harm for which its remedies at law may be inadequate. Publisher hereby agrees that the Company will be entitled, in addition to any other remedies available to it at law or in equity, to obtain immediate injunctive relief to prevent such unauthorized actions.

			</p>
			<h3>Complete Agreement</h3>
			<p>
			These Terms of Service constitute the entire agreement between the Company and Publisher with respect to the subject matter hereof and supersedes all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.

			</p>
			<h3>Modification</h3>
			<p>
			The Company may modify the terms of these Terms of Service in its sole discretion and such modifications shall take effect and be binding on Publisher on the earliest date on which they are posted to the Company's publicly available website or delivered to Publisher via electronic or physical delivery. No one other than the Company has the right to modify these Terms of Service.

			</p>
			<h3>Waiver of Breach</h3>
			<p>
			No term or provision of these Terms of Service shall be deemed waived and no breach excused, unless such waiver or consent shall be in writing and signed by the Company. Any consent by the Company to, or waiver of, a breach by Publisher whether expressed or implied, shall not constitute consent to, waiver of, or excuse for, any other breach or any subsequent breach, except as may be expressly provided by the Company.

			</p>
			<h3>Other Terms:</h3>
			<p>
			Company and Publisher are independent contractors and nothing herein constitutes or creates an employer-employee, agency, joint venture or representative relationship between them.
			Publisher agrees and certifies that it will not export or re-export, directly or indirectly, the Products contrary to the laws of the country or any other country from or to which such export or re-export may occur.
			These Terms of Service constitute the entire agreement between the Company and Publisher with respect to the subject matter hereof, and supersedes all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.
			Publisher may not assign or transfer this Agreement to any third party without the Company's express written consent. Any assignment or transfer made in the absence of such consent shall be void and in the event of such unauthorized assignment or transfer the Company may immediately terminate these Terms of Service.
			The failure of either Party to enforce its rights under this Agreement at any time for any period shall not be construed as a waiver of such rights.
			Headings used in this Agreement are for convenience only and shall have no legal effect on interpretation of the Agreement.
			</p>
		</div>
	)
}

export default TandC
